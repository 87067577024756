// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-lander-lander-js": () => import("/opt/build/repo/src/templates/Lander/Lander.js" /* webpackChunkName: "component---src-templates-lander-lander-js" */),
  "component---src-templates-page-page-js": () => import("/opt/build/repo/src/templates/Page/Page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-les-les-js": () => import("/opt/build/repo/src/templates/Les/Les.js" /* webpackChunkName: "component---src-templates-les-les-js" */),
  "component---src-templates-lessen-index-lessen-index-js": () => import("/opt/build/repo/src/templates/LessenIndex/LessenIndex.js" /* webpackChunkName: "component---src-templates-lessen-index-lessen-index-js" */),
  "component---src-templates-les-introductie-les-introductie-js": () => import("/opt/build/repo/src/templates/LesIntroductie/LesIntroductie.js" /* webpackChunkName: "component---src-templates-les-introductie-les-introductie-js" */),
  "component---src-templates-blog-post-blog-post-js": () => import("/opt/build/repo/src/templates/BlogPost/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-blog-post-js" */),
  "component---src-templates-locatie-locatie-js": () => import("/opt/build/repo/src/templates/Locatie/Locatie.js" /* webpackChunkName: "component---src-templates-locatie-locatie-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

